import paths from 'app/router/paths';
import { lazy } from 'react';
import { getAdminSendMailLogsCall } from 'api/app/admin/logs/email';
import { LoaderFunctionArgs } from 'react-router-dom';
import { queryStringToObject } from 'utils/http/queryStringToObject';
import { getAdminErrorLogsCall } from 'api/app/admin/logs/error';

const EmailLogsPage = lazy(() => import('app/pages/Admin/Logs/EmailLogs'));
const ErrorLogsPage = lazy(() => import('app/pages/Admin/Logs/ErrorLogs'));

const adminLogs = [
  {
    path: paths.admin.logs.emailLogs,
    element: <EmailLogsPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminSendMailLogsCall(searchQuery);
    },
  },
  {
    path: paths.admin.logs.errorLogs,
    element: <ErrorLogsPage />,
    loader: (loader: LoaderFunctionArgs) => {
      const searchQuery = queryStringToObject(loader.request.url);

      return getAdminErrorLogsCall(searchQuery);
    },
  },
];

export default adminLogs;
