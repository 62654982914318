import { TFunction } from 'i18next';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import paths from 'app/router/paths';
import { SettingsMenuSection } from 'app/components/Layout/SettingsWrapper/_components/SettingsMenu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

const adminSettingsSubNavigationLinks = (t: TFunction): SettingsMenuSection[] => [
  {
    title: t('dashboard'),
    links: [
      {
        icon: <DashboardIcon />,
        to: paths.admin.dashboard,
        name: t('home'),
      },
    ],
  },
  {
    title: t('emails'),
    links: [
      {
        icon: <DocumentScannerIcon />,
        to: paths.admin.email.emailTemplates,
        name: t('emailTemplates'),
      },
    ],
  },
  {
    title: t('logs'),
    links: [
      {
        icon: <MailOutlineIcon />,
        to: paths.admin.logs.emailLogs,
        name: t('sendMailLogs'),
      },
      {
        icon: <BugReportOutlinedIcon />,
        to: paths.admin.logs.errorLogs,
        name: t('errorLogs'),
      },
    ],
  },
];

export default adminSettingsSubNavigationLinks;
