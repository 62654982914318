import paths from '../../paths';
import { lazy } from 'react';
import { getRestaurantIntegrationsCall } from 'api/app/restaurant/settings/marketingTools';

const ExperiencesPage = lazy(() => import('app/pages/RestaurantSettings/Experiences'));
const AddExperiencePage = lazy(
  () => import('app/pages/RestaurantSettings/Experiences/AddExperience'),
);
const EventsPage = lazy(() => import('app/pages/RestaurantSettings/Events'));
const AddEventPage = lazy(() => import('app/pages/RestaurantSettings/Events/AddEvent'));
const SpecialOffers = lazy(() => import('app/pages/RestaurantSettings/SpecialOffers'));
const AddSpecialOffer = lazy(
  () => import('app/pages/RestaurantSettings/SpecialOffers/AddSpecialOffer'),
);
const DepositsPage = lazy(() => import('app/pages/RestaurantSettings/Deposits'));
const AddDepositPage = lazy(() => import('app/pages/RestaurantSettings/Deposits/AddDeposit'));
const ThirdPartyIntegrationsPage = lazy(
  () => import('app/pages/RestaurantSettings/MarketingTools/ThirdPartyIntegrations'),
);

const marketingTools = [
  {
    path: paths.settings.marketingTools.experiences.index,
    element: <ExperiencesPage />,
  },
  {
    path: paths.settings.marketingTools.experiences.create,
    element: <AddExperiencePage />,
  },
  {
    path: paths.settings.marketingTools.events.index,
    element: <EventsPage />,
  },
  {
    path: paths.settings.marketingTools.events.crate,
    element: <AddEventPage />,
  },
  {
    path: paths.settings.marketingTools.specialOffers.index,
    element: <SpecialOffers />,
  },
  {
    path: paths.settings.marketingTools.specialOffers.create,
    element: <AddSpecialOffer />,
  },
  {
    path: paths.settings.marketingTools.paymentsAndDeposits.index,
    element: <DepositsPage />,
  },
  {
    path: paths.settings.marketingTools.paymentsAndDeposits.create,
    element: <AddDepositPage />,
  },
  {
    path: paths.settings.marketingTools.thirdPartyIntegrations.index,
    element: <ThirdPartyIntegrationsPage />,
    loader: async () => await getRestaurantIntegrationsCall(),
  },
];

export default marketingTools;
