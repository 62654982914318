import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledTableCell = styled(TableCell)(({ theme, ...props }) => ({
  fontSize: theme.spacing(2),
  fontWeight: 600,
  color: theme.palette.navyBlue,
  width: props.children ? 'unset' : theme.spacing(7),
  whiteSpace: 'nowrap',
}));
