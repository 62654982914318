import { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TableCell } from '@mui/material';
import { IOption } from 'types/app/config';
import { StyledTableRow } from 'app/pages/RestaurantSettings/_components/StyledTableRow';
import { ReservationLengthEdit } from 'app/pages/RestaurantSettings/TimeManagement/_components/ReservationLengthEdit';
import { MinMaxGuestsRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/MinMaxGuestsRange';
import { BookingLengthRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/BookingLengthRange';
import { AddRange } from 'app/pages/RestaurantSettings/TimeManagement/_components/AddRange';
import { timeManagementFieldNames } from 'app/pages/RestaurantSettings/TimeManagement/_config';
import { doesRangesOverlap } from 'app/pages/RestaurantSettings/TimeManagement/_utils';
import { useTranslation } from 'react-i18next';
import { GuestsOverlappingModal } from 'app/pages/RestaurantSettings/TimeManagement/_components/GuestsOverlappingModal';

interface OwnProps {
  name: string;
  options: IOption[];
}

const inputStyles = {
  width: '46px',
  textAlign: 'center',
  '& .MuiInputBase-input': {
    paddingTop: '10px',
    paddingBottom: '10px',
    textAlign: 'center',
  },
  '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
    {
      WebkitAppearance: 'none',
      margin: 0,
    },
  '& input[type=number]': {
    MozAppearance: 'textfield',
  },
};

export const TableRowRanges = ({ name, options }: OwnProps) => {
  const [isOverlappingModalOpen, setIsOverlappingModalOpen] = useState(false);
  const [editIndexes, setEditIndexes] = useState<number[]>([]);
  const {
    control,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  const { fields, remove, update } = useFieldArray({
    control,
    name,
  });
  const ranges = getValues(timeManagementFieldNames.ranges);
  const rangeCanBeRemoved = fields.length > 1;

  const handleRemove = (index: number) => {
    if (rangeCanBeRemoved) {
      remove(index);
    }
  };

  const toggleEdit = (index: number) => {
    const currentEditState = editIndexes.includes(index);
    setEditIndexes(
      currentEditState ? editIndexes.filter((i) => i !== index) : [...editIndexes, index],
    );
  };

  const openOverlappingModal = () => {
    setIsOverlappingModalOpen(true);
  };

  const onUpdate = async (index: number) => {
    const isValid = await trigger([
      `${name}[${index}].${timeManagementFieldNames.min_guests}`,
      `${name}[${index}].${timeManagementFieldNames.max_guests}`,
      `${name}[${index}].${timeManagementFieldNames.min_length}`,
      `${name}[${index}].${timeManagementFieldNames.min_phone_length}`,
      `${name}[${index}].${timeManagementFieldNames.max_length}`,
    ]);

    if (!isValid) {
      return;
    }

    const newRange = getValues(`${name}[${index}]`);
    const otherRanges = ranges.filter((_: Range, idx: number) => idx !== index);

    const overlapErrorName = `${name}[${index}].overlap`;

    if (doesRangesOverlap(otherRanges, newRange)) {
      openOverlappingModal();
      setError(overlapErrorName, {
        type: 'manual',
        message: t('doesRangesOverlapMessage'),
      });
      return;
    } else {
      clearErrors(overlapErrorName);
    }

    update(index, newRange);
    toggleEdit(index);
  };

  const updatedOptions = options?.map((option) => ({
    ...option,
    value: Number(option.value),
  }));

  return (
    <>
      {fields.map((field: any, index) => {
        const isEdit = editIndexes.includes(index);

        return (
          <StyledTableRow key={field.id}>
            <TableCell>
              <MinMaxGuestsRange
                isEdit={isEdit}
                minName={`${name}.${index}.${timeManagementFieldNames.min_guests}`}
                maxName={`${name}.${index}.${timeManagementFieldNames.max_guests}`}
                control={control}
                errors={errors}
                inputStyles={inputStyles}
                field={field}
              />
            </TableCell>
            <TableCell>
              <BookingLengthRange
                isEdit={isEdit}
                fieldName={`${name}.${index}.${timeManagementFieldNames.max_length}`}
                options={updatedOptions}
                currentFieldValue={field.max_length}
              />
            </TableCell>
            <TableCell>
              <BookingLengthRange
                isEdit={isEdit}
                fieldName={`${name}.${index}.${timeManagementFieldNames.min_phone_length}`}
                options={updatedOptions}
                currentFieldValue={field.min_phone_length}
              />
            </TableCell>
            <TableCell>
              <BookingLengthRange
                isEdit={isEdit}
                fieldName={`${name}.${index}.${timeManagementFieldNames.min_length}`}
                options={updatedOptions}
                currentFieldValue={field.min_length}
              />
            </TableCell>
            <TableCell>
              <ReservationLengthEdit
                allowToRemove={rangeCanBeRemoved}
                isEdit={isEdit}
                onDelete={() => handleRemove(index)}
                onToggleEdit={() => toggleEdit(index)}
                onUpdate={() => onUpdate(index)}
              />
            </TableCell>
          </StyledTableRow>
        );
      })}
      <AddRange
        options={updatedOptions}
        inputStyles={inputStyles}
        openOverlappingModal={openOverlappingModal}
      />
      <GuestsOverlappingModal
        isOpen={isOverlappingModalOpen}
        onClose={() => setIsOverlappingModalOpen(false)}
      />
    </>
  );
};
